<template>
  <LiefengContent>
    <template v-slot:title>邀请用户注册</template>
    <template v-slot:toolsbarRight>
      <Button type="primary" @click="clearRow(selectArr)">批量清零</Button>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :tableData="tableData"
        :talbeColumns="talbeColumns"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :curPage="curPage"
        @hadlePageSize="hadlePageSize"
        @tableSelect="tableSelect"
      ></LiefengTable>
      <!-- 邀请用户弹窗 -->
      <LiefengModal
        title="用户邀请"
        :value="inviteStatus"
        width="500px"
        height="190px"
        @input="inviteStatusFn"
      >
        <template v-slot:contentarea>
          <Form
            :model="inviteData"
            :rules="inviteValidator"
            :label-colon="true"
            :label-width="90"
          >
            
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="inviteStatusFn(false)" style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="inviteSave">确定</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/statisticsuserregister')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
         tableData: [],
      talbeColumns: [
        {
          type: "selection",
          width: 80,
          align: "center",
        },
        {
          title: "排行",
          key: "sort",
          align: "center",
          minWidth: 140,
        },
        {
          title: "姓名",
          key: "name",
          align: "center",
          minWidth: 140,
        },
        {
          title: "手机号",
          key: "phone",
          align: "center",
          minWidth: 180,
        },
        {
          title: "邀请人数",
          key: "invitePeoper",
          align: "center",
          minWidth: 140,
        },
        {
          title: "社区",
          key: "community",
          align: "center",
          minWidth: 160,
         
        },
        {
          title: "注册时间",
          key: "registrationTime",
          align: "center",
          minWidth: 180,
        },
        {
          title: "操作",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                 
                  on: {
                    click: () => {
                        this.inviteId = params.row.id,
                        this.inviteStatusFn(true)
                    },
                  },
                },
                "邀请用户"
              ),
            ]);
          },
        },
      ],
      loading: false,
      pageSize: 20,
      total: 0,
      curPage: 1,
      //表格选中
      selectArr: [],
      //邀请用户
      inviteId: '',
        inviteStatus: false,
        inviteData: {},

    };
  },
  methods: {
      //邀请用户弹窗确定
      inviteSave() {

      },
      inviteStatusFn(status) {
          this.inviteStatus = status;
      },
       //清零
    clearRow(id) {
      this.$Modal.confirm({
        title: "删除确定",
        content: "您正在对信息进行清零，是否继续？",
        onOk: () => {
          this.$post("/testjson/statistics/integraldata.json", {
            id: id.join(","),
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "操作成功",
                });
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              this.$Message.error({
                background: true,
                content: "操作失败",
              });
            });
        },
      });
    },
      //表格多选
    tableSelect(row) {
      this.selectArr = [];
      row.map((item, index) => {
        this.selectArr.push(item.id);
      });
    },
       hadlePageSize(obj) {
      this.loading = true;
      this.$get("/testjson/statistics/userregisterdata.json", {
        pageSize: obj.pageSize,
        curPage: obj.page,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item,index) => {
               if(item.registrationTime) {
                item.registrationTime = this.$core.formatDate(
                new Date(item.registrationTime),
                "yyyy-MM-dd hh:mm:ss"
              );
              }
            })
            this.tableData = res.dataList;
            this.pageSize = res.pageSize;
            this.total = res.total;
            this.curPage = res.curPage;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$Message.error({
            background: true,
            content: "获取数据失败",
          });
        });
    },
  },
  created() {
    this.hadlePageSize({
      pageSize: this.pageSize,
      page: this.curPage,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
</style>